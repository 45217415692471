import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/api-keys',
    name: 'api-keys',  
    component: () => import(/* webpackChunkName: "api-keys" */ '../views/ApiKeysView.vue')
  },
  {
    path: '/login',
    name: 'login',  
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/chats',
    name: 'chats',  
    component: () => import(/* webpackChunkName: "chats" */ '../views/ChatsView.vue')
  },
  {
    path: '/configuration',
    name: 'configuration',  
    component: () => import(/* webpackChunkName: "configuration" */ '../views/ConfigurationView.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL), // TODO: use createWebHistory() for production
  routes
})

export default router
