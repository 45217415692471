import { hp, HoneyPotsState } from "@/clients/honeypots";
import { tl } from "@/clients/telegram";
import { useConfigStore } from "@/store/config";
import { computed } from "vue";
import { toggleHoneyPots } from "./useHoneyPots";

export function useHoneyChat() {
  const config = useConfigStore();
  const toggleHoneyChat = () => {
    if (tl.client) {
      toggleHoneyPots(tl.client, config.openai, config.honeychat);
    }
  };
  const hpState = computed(() => {
    if (hp.state.value === HoneyPotsState.enabled) {
      return "Stop";
    } else if (hp.state.value === HoneyPotsState.disabled) {
      return "Start";
    } else {
      return "Loading";
    }
  });

  return {
    toggleHoneyChat,
    hpState,
  }
}