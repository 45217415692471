import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import { plugin, defaultConfig } from '@formkit/vue'
import formkitConfig from '../formkit.config';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import '@/assets/index.css'

const app = createApp(App);
const pinia = createPinia()

app
  .use(pinia)
  .use(router)
  .use(autoAnimatePlugin)
  .use(plugin, defaultConfig(formkitConfig))
  .mount('#app')
