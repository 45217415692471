import { OpenAIConfigValid, HoneyChatConfigValid } from './../store/config';
import { hp, HoneyPotsState } from './../clients/honeypots';
import { Configuration, HoneyPots, TLClient } from "honeypots/src";
import { HoneyPot } from "honeypots/src/honeypot/honeypot";

export async function toggleHoneyPots(tlClient: TLClient, openai: OpenAIConfigValid, honeypotConfig: HoneyChatConfigValid) {
  if (hp.state.value !== HoneyPotsState.loading) {
    hp.state.value = HoneyPotsState.loading;
    if (hp.hpController) {
      if (hp.hpController.isActive) {
        await disableHoneyPots();
      } else {
        await enableHoneyPots();
      }
    } else {
      createNewHoneyPots(tlClient, openai, honeypotConfig);
    }
  }
}

export async function enableHoneyPots() {
  console.log("Watching honeypots");
  if (hp.hpController) {
    try {
      await hp.hpController.watch();
      hp.state.value = HoneyPotsState.enabled;
    } catch (e) {
      console.error(e);
      hp.state.value = HoneyPotsState.disabled;
    }
  }
}

export async function disableHoneyPots() {
  console.log("Unwatching honeypots");
  if (hp.hpController) {
    try {
      await hp.hpController.destroy();
      hp.state.value = HoneyPotsState.disabled;
    } catch (e) {
      console.error(e);
      hp.state.value = HoneyPotsState.enabled;
    }
  }
}

async function formatHoneyPotsMessages(honeypots: HoneyPot[]) {
  return honeypots.map((honeypot) => {
    return {
      id: honeypot.chatId.userId.toString(),
      from: honeypot.recipientName,
      to: honeypot.name,
      history: honeypot.conversationHistory.messages,
    };
  });
}


async function createNewHoneyPots(tlClient: TLClient, openai: OpenAIConfigValid, honeypotConfig: HoneyChatConfigValid) {
  console.log("Creating new honeypots controller");
  const configuration = {
    tlClient: tlClient,
    honeypotFolderName: honeypotConfig.honeypotFolderName,
    onHoneypotChange: async (honeypots: HoneyPot[]) => {
      hp.honeypots.value = honeypots;
      hp.chats.value = await formatHoneyPotsMessages(honeypots);
    },
    config: {
      honeypotConfig: {
        ...honeypotConfig,
        openai: new Configuration(openai),
        personality: (name: string, recipientName: string): string =>
          honeypotConfig.personality.replace(/NAME/g, name).replace(/RECIPIENT/g, recipientName),

      },
    },
  };
  try {
    hp.hpController = new HoneyPots(configuration);
    await hp.hpController.watch();
    hp.state.value = HoneyPotsState.enabled;
  } catch (e) {
    console.error(e);
    hp.state.value = HoneyPotsState.disabled;
  }
}