import { tl } from "@/clients/telegram";
import { isTelegramConfigValid, useConfigStore } from "@/store/config";
import { StringSession, TLClient } from "honeypots/src";


export const createNewClient = () => {
  const config = useConfigStore();
  if (isTelegramConfigValid(config.telegram)) {
    console.log("Creating new client...");
    tl.client = new TLClient({
      apiId: config.telegram.apiId,
      apiHash: config.telegram.apiHash,
      session: new StringSession(config.telegram.session || ""),
    });
  }
};

export const tryQuickLogin = async () => {
  const config = useConfigStore();
  if (isTelegramConfigValid(config.telegram) && config.telegram.session) {
    console.log("Attempting quick login with saved session...");
    createNewClient();
    if (tl.client) {
      await tl.client.init({
        getPhoneNumberIfNeeded: async () => "",
        getPhoneCodeIfNeeded: async () => "",
        getPasswordIfNeeded: async () => "",
      })
    }
  }
};
