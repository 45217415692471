import { HoneyPots } from 'honeypots/src';
import { HoneyPot } from 'honeypots/src/honeypot/honeypot';
import { Message } from 'honeypots/src/models/message';
import { ref } from 'vue';

export enum HoneyPotsState { enabled, disabled, loading }

export const hp = {
  hpController: null as HoneyPots | null,
  honeypots: ref<HoneyPot[]>([]),
  chats: ref<HoneyPotChat[]>([]),
  state: ref<HoneyPotsState>(HoneyPotsState.disabled),
}

interface HoneyPotChat {
  id: string;
  from: string;
  to: string;
  history: Message[];
}
