
import { defineComponent } from "vue";
import { marked } from "marked";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const insane = require("insane");

export default defineComponent({
  name: "FormCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
      required: false,
    },
  },
  setup() {
    return { marked, insane };
  },
});
