import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-4xl bg-slate-200 p-10 mt-7 max-w-md" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: "mb-4 blue-anchors",
      innerHTML: _ctx.insane(_ctx.marked.parse(_ctx.description))
    }, null, 8, _hoisted_3),
    _renderSlot(_ctx.$slots, "default")
  ]))
}