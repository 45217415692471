import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'


export const useConfigStore = defineStore('config', {
  state: () => useStorage('config', {
    openai: {
      apiKey: '',
    },
    telegram: {
      apiId: null as number | null,
      apiHash: null as string | null,
      session: null as string | null,
    },
    honeychat: {
      honeypotFolderName: 'HONEYPOTS',
      messageHistoryLimit: 10,
      aiModel: 'text-curie-001',
      minSleepTime: 1,
      maxSleepTime: 30,
      personality: `NAME is a friend that enjoys hockey and lives in Canada. NAME is a bit grumpy, and is always mad at the neighborhood kids, for making too much noise.`
    }
  })
})

export interface OpenAIConfigValid {
  apiKey: string;
}

export interface HoneyChatConfigValid {
  honeypotFolderName: string;
  messageHistoryLimit: number;
  aiModel: string;
  minSleepTime: number;
  maxSleepTime: number;
  personality: string;
}

export interface TelegramConfigValid {
  apiId: number;
  apiHash: string;
  session: null | string;
}

export function isTelegramConfigValid(config: object): config is TelegramConfigValid {
  return (
    (config as TelegramConfigValid).apiId !== null &&
    (config as TelegramConfigValid).apiHash !== null
  );
}
